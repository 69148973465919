<template>
  <v-col
    cols="12"
    md="6"
  >
    <v-card>
      <v-card-text>
        <h2 class="text-h5">
          Проверка статуса
        </h2>

        <!-- ССЫЛКА НА ОПЛАТУ -->
        <v-text-field
          v-model="coinbaseLink"
          label="Ссылка на оплату"
          dense
          outlined
          hide-details="auto"
          :error="touched && formIsInvalid"
          :error-messages="touched && formIsInvalid ? 'Нет ссылки' : null"
          class="mt-3"
        />
        <!-- ССЫЛКА НА ОПЛАТУ КОНЕЦ -->

        <!-- ПРОВЕРИТЬ -->
        <v-btn
          color="primary"
          class="mt-3"
          @click="check"
        >
          Проверить
        </v-btn>
        <!-- ПРОВЕРИТЬ КОНЕЦ -->

        <v-alert
          v-if="coinbaseStatus"
          :icon="false"
          type="info"
          border="left"
          outlined
          text
          class="mt-3"
        >
          {{ getStatus(coinbaseStatus) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'CoinbaseCheckStatus',

  data() {
    return {
      touched: false,
      coinbaseLink: '',
      loading: false,
      coinbaseStatus: null,
      urlPattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    };
  },

  computed: {
    formIsInvalid() {
      return !this.urlPattern.test(this.coinbaseLink);
    },
  },

  watch: {
    coinbaseLink() {
      this.touched = false;
    },
  },

  methods: {
    async check() {
      if (this.formIsInvalid) {
        this.touched = true;
        return;
      }

      try {
        this.loading = true;
        this.coinbaseStatus = null;

        const coinbaseId = /[^/]*$/.exec(this.coinbaseLink)[0];
        const response = await this.api(`/admin/subscription/coinbase-invoice/${coinbaseId}`);

        const { status, data } = response;
        if (status === 200 && data.success && data.data) {
          this.coinbaseStatus = data.data.status ? data.data.status : null;
        }

        this.loading = false;
      } catch (_) {
        this.loading = false;
      }
    },

    getStatus(status) {
      if (status && typeof status === 'string') {
        switch (status.toLowerCase()) {
          case 'new':
            return 'Создан';
          case 'pending':
            return 'Ждёт подтверждений';
          case 'completed':
            return 'Оплачен и подтверждён';
          default:
            return status;
        }
      }

      return '';
    },
  },
};
</script>
