<template>
  <v-row>
    <create-invoice />
    <check-status />
  </v-row>
</template>

<script>
import CreateInvoice from '../components/coinbase/CoinbaseCreateInvoice.vue';
import CheckStatus from '../components/coinbase/CoinbaseCheckStatus.vue';

export default {
  name: 'Coinbase',
  components: {
    CreateInvoice,
    CheckStatus,
  },
};
</script>
