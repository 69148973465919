<template>
  <v-col
    cols="12"
    md="6"
  >
    <v-card>
      <v-card-text>
        <h2 class="text-h5">
          Создание инвойса
        </h2>

        <!-- EMAIL ЮЗЕРА -->
        <v-text-field
          v-model="userEmail"
          label="Email юзера"
          dense
          outlined
          hide-details="auto"
          :error="touched && !emailPattern.test(userEmail)"
          :error-messages="touched && !emailPattern.test(userEmail) ? 'Нет почты' : null"
          class="mt-3"
        />
        <!-- EMAIL ЮЗЕРА КОНЕЦ -->

        <!-- СУММА -->
        <v-text-field
          v-model="amount"
          prefix="$"
          label="Сумма в USD"
          type="number"
          step="0.01"
          dense
          outlined
          hide-details="auto"
          :error="touched && amount <= 0"
          :error-messages="touched && amount <= 0 ? 'Сумма невалидна' : null"
          class="mt-3"
        />
        <!-- СУММА КОНЕЦ -->

        <!-- СОЗДАТЬ -->
        <v-btn
          color="primary"
          class="mt-3"
          @click="create"
        >
          Создать
        </v-btn>
        <!-- СОЗДАТЬ КОНЕЦ -->

        <!-- ССЫЛКА -->
        <div
          v-if="coinbaseLink"
          class="mt-3 d-flex align-center"
        >
          <v-textarea
            :value="coinbaseLink"
            filled
            outlined
            hide-details
            rows="1"
            auto-grow
            disabled
            class="mr-2"
          />

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                small
                v-on="on"
                @click="copyToClipboard(coinbaseLink)"
              >
                <v-icon :size="16">
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </template>
            <span>Скопировать</span>
          </v-tooltip>
        </div>
        <!-- ССЫЛКА КОНЕЦ -->
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import clipboard from '@/mixins/clipboard';

export default {
  name: 'CoinbaseCreateInvoice',

  mixins: [clipboard],

  data() {
    return {
      emailPattern: /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/,
      touched: false,
      userEmail: '',
      amount: '',
      loading: false,
      coinbaseLink: null,
    };
  },

  computed: {
    formIsInvalid() {
      return !this.emailPattern.test(this.userEmail) || this.amount <= 0;
    },
  },

  watch: {
    userEmail() {
      this.touched = false;
    },
    amount() {
      this.touched = false;
    },
  },

  methods: {
    async create() {
      if (this.formIsInvalid) {
        this.touched = true;
        return;
      }

      try {
        this.loading = true;
        this.coinbaseLink = null;
        const response = await this.api.post('/admin/subscription/coinbase-invoice', {
          username: this.userEmail,
          amount: this.amount,
        });

        const { status, data } = response;
        if (status === 200 && data.success && data.data) {
          this.coinbaseLink = data.data.link ? data.data.link : null;
        }

        this.loading = false;
      } catch (_) {
        this.loading = false;
      }
    },
  },
};
</script>
